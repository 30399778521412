@import 'common/_variables';

.filters {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-top: 24px;
	border-top: 1px solid #D7D8D9;
	margin-bottom: 64px;

	&__head,
	&__footer {
		display: none;
	}
}

.filter {
	position: relative;
	margin-bottom: 8px;

	&__name {
		position: relative;
		display: flex;
		align-items: center;
		height: 40px;
		padding: 0 45px 0 16px;
		border: 1px solid #DADEE2;
		border-radius: 6px;
		font-weight: 600;
		font-size: 14px;
		line-height: 143%;
		color: #6B7B8E;
		cursor: pointer;
		margin-right: 15px;

		&:after {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			content: '';
			display: block;
			width: 14px;
			height: 8px;
			background: url(../img/common/icons.png) no-repeat 0 -40px;
		}

		&:hover:after {
			background-position: -25px -40px;
		}

		&.open:after {
			transform: rotate(180deg);
			margin-top: -4px;
		}

		&-open {
			display: none;
		}
	}

	&__drop {
		position: absolute;
		z-index: 1;
		top: 100%;
		display: none;
		padding: 24px;
		background: #fff;
		box-shadow: 0px 4px 14px 3px rgba(130, 146, 186, 0.45);
		border: 1px solid #DADEE2;
		border-radius: 6px;
		margin-top: 5px;

		.scrollblock {
			max-height: 300px;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: #DADEE2 transparent;
			margin-right: -10px;
		
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;              
				border-radius: 2px;
			}
		
			&::-webkit-scrollbar-track {
				border-radius: 2px;
				background: none
			}
		
			&::-webkit-scrollbar-thumb {
				width: 4px;
				height: 4px;  
				background-color: #DADEE2;    
				border-radius: 2px;       
			}
		}
	}

	&__list {

		&-item {
			margin-bottom: 8px;
		}

		&-label {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 15px;
			line-height: 20px;
			cursor: pointer;

			& > DIV {
				white-space: nowrap;
			}
		}
	}

	DT {
		font-weight: bold;
		font-size: 16px;
		line-height: 18px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #6B7B8E;
		margin-bottom: 8px;
	}
	
	DD {
		padding-bottom: 12px;
	}
}

.choice {

	&-value {
		position: relative;
		display: flex;
		align-items: center;
		height: 40px;
		padding: 0 45px 0 16px;
		border-radius: 6px;
		background: rgba(19, 168, 252, 0.1);
		font-size: 14px;
		line-height: 143%;
		color: #000;
		margin-right: 15px;
		margin-bottom: 8px;

		&-del {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translate(0, -50%);
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: url(../img/common/icons.png) no-repeat 0 -60px;
			cursor: pointer;

			&:hover {
				background-position: -25px -60px;
			}
		}
	}

	&-delete {
		position: relative;
		display: flex;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 6px;
		background: rgba(19, 168, 252, 0.1);
		font-size: 14px;
		line-height: 143%;
		color: #000;
		text-indent: -10000px;
		cursor: pointer;
		margin-right: 15px;
		margin-bottom: 8px;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background: url(../img/common/icons.png) no-repeat 0 -60px;
		}

		&:hover:after {
			background-position: -25px -60px;
		}
	}
}

.price-filter {
	width: 306px;

	&__head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		
		DIV {
			display: flex;
			align-items: center;
			font-size: 13px;
		}

		SPAN {
			display: flex;
			align-items: center;
			width: 67px;
			height: 25px;
			padding: 0 8px;
			background: #F8F8F8;
			border-radius: 4px;
			font-size: 14px;
			color: #6B7B8E;
			margin-left: 12px;
		}
	}

	&__slider {
		position: relative;
		height: 2px;
		background: #DADEE2;
	
		DIV {
			position: absolute;
			z-index: 1;
			top: 0;
			height: 100%;
			background: $lightblue;
		}

		SPAN {
			position: absolute;
			z-index: 2;
			top: -12px;
			width: 24px;
			height: 24px;
			background: #fff;
			border: 2px solid $lightblue;
			border-radius: 50%;
			cursor: default;
			outline:none;
			margin-left: -12px;
		}
	}
}

.checkbox-box {
	position: relative;
	width: 19px;
	margin-left: 10px;

	.checkbox {
		position: absolute; 
		z-index: 2; 
		left: 0; 
		top: -5px; 
		padding: 0; 
		opacity: 0; 
		border: none; 
		margin: 0 !important;
	}

	.checkbox,
	.checkbox + .checkbox-icon {
		width: 9px; 
		height: 9px; 
		padding: 0; 
		margin: 0;
	}

	.checkbox + .checkbox-icon {
		position: absolute; 
		z-index: 1; 
		left: 0; 
		top: -5px; 
		background-position: 0 0; 
		border: none; 
		text-align: center;
	}

	.checkbox:checked + .checkbox-icon {
		background: url(../img/common/icons.png) no-repeat -25px 0;
	}
}
