.mentors {
	padding: 89px 0 103px;
	
	&__head {
		margin-bottom: 37px;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin-right: -30px;
		margin-left: -30px;
	}

	&__col {
		position: relative;
		flex: 0 0 20%;
		width: 100%;
		max-width: 20%;
		min-height: 1px;
		padding-right: 30px;
		padding-left: 30px;

		&[data-more-hidden] {
			padding-bottom: 0;

			.mentors__item {
				position: absolute;
				top: 9999px;
				left: -9999px;
				opacity: 0;
			}
		}
	}	

	&__item {
		position: relative;
		display: flex; 
        flex-direction: column;
        max-width: 310px;
		min-height: 100%;
		padding-bottom: 45px;
		margin: 0 auto;
	}

	&__photo {
        margin-bottom: 32px;

		IMG {
            display: block;
            border-radius: 10px 0;
			margin: 0 auto;
		}
	}
	
	&__info {
		flex-grow: 1; 
	}

	&__name {
        font-family: $proximanovacn;
		font-weight: 400;
		font-size: 26px;
		line-height: 123%;
        margin-bottom: 8px;
        
        SPAN {
            font-weight: 700;
        }
	}

	&__post {
		line-height: 150%;
        letter-spacing: 0.186182px;
	}

	&__more-btn {
        display: block;
        max-width: 484px;
        margin: 30px auto 0 auto;
	}
	
	.version-authors & {
		padding-top: 158px;

		&__head {
			margin: 0 0 56px 0;

			.section-head__number {
				display: none;
			}
		}
	}
}
