@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('fonts/ProximaNova-Extrabld.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('fonts/ProximaNova-Light.woff2') format('woff2'),
        url('fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Proxima Nova Cn';
    src: url('fonts/ProximaNovaCond-Bold.woff2') format('woff2'),
        url('fonts/ProximaNovaCond-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Cn';
    src: url('fonts/ProximaNovaCond-Semibold.woff2') format('woff2'),
        url('fonts/ProximaNovaCond-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Cn';
    src: url('fonts/ProximaNovaCond-Regular.woff2') format('woff2'),
        url('fonts/ProximaNovaCond-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'rouble';
    src: url('fonts/rouble.woff2') format('woff2'),
        url('fonts/rouble.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}




