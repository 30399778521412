.bottom-menu {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 25px 0 24px 0;
    background: #fff;
    box-shadow: 0px -4px 12px rgba(64, 80, 120, 0.1);

    &__list {
        display: flex;
        justify-content: space-around;
        max-width: 566px;
        color: #0044DB;
        margin: 0 auto;
    }

    &__link {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: #0044DB;
        opacity: 0.5;

        &:before {
            content: '';
            display: block;
            width: 21px;
            height: 20px;
            background: url(../img/bottom-menu/icons.png) no-repeat;
            margin: 0 auto;
            opacity: 0.5;
        }

        &_1:before {
            background-position: 0 0;
        }

        &_2:before {
            background-position: 0 -23px;
        }

        &_3:before {
            background-position: 0 -46px;
        }

        &_4:before {
            background-position: 0 -69px;
        }

        &_5:before {
            background-position: 0 -92px;
        }

        &_current {
            opacity: 1;

            &:before {
                opacity: 1;
            }
        }
    }
}