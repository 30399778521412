.header {
    position: fixed;
    width: 100%;
    max-width: 1920px;
    left: auto;
    right: auto;
    top: 30px;
    z-index: 10;
    font-weight: 500;
    color: #405078;

    .page-scrolled & {
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    }

    &_nofix {
        position: static;
    }

    &__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 114px;
        transition: 0.3s;

        .page-scrolled & {
            min-height: 60px;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__icon {
        display: none;

        &-menu {
            position: relative;
            z-index: 11;
            display: block;
            width: 20px;
            height: 20px;
            background: url(../img/header/icons.png) no-repeat 0 -110px;
            cursor: pointer;
        }

        &-menu.open {
            background-position: 0 -145px;
        }
    }

    &__logo {
        position: relative;
        z-index: 11;
    }

    &__menu {
        display: flex;
        justify-content: center;

        &-item {
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            margin: 0 24px;
        }

        &-link {

            .version-books & {

                &_book {
                    color: $lightblue;
                }
            }
        }
    }

    &__right {
        position: relative;
        z-index: 11;
        display: flex;
        align-items: center;
    }

    &__basket {
        font-weight: 600;
        font-size: 18px;
        line-height: 139%;
        white-space: nowrap;
        margin-right: 41px;

        &:before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url(../img/header/icons.png) no-repeat 0 -70px;
            vertical-align: -5px;
            margin-right: 13px;
        }
        
    }
    &__basket-coount{
        display: none;
    }

    &__profile {
        display: block;
        width: 48px;
        height: 48px;
        background: url(../img/header/icons.png) no-repeat 0 0;
        text-indent: -10000px;
    }
}

/* Search ------------------------------------------------------------------- */
.search {
    max-width: 30%;
    padding-top: 15px;
    margin: 0 0 0 auto;
    
    .version-registration &,
    .version-login & {
        display: none;
    }

    .page-scrolled & {
        padding: 0 0 10px 0;
    }

    &__form {
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
        background: #EEEEF8;
        border-radius: 13.8393px;
        transition: 0.1s;

        .page-scrolled & {
            height: 50px;
        }

        &:before {
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translate(0, -50%); 
            content: '';
            display: block; 
            width: 24px;
            height: 24px;
            background: url(../img/header/icons.png) no-repeat 0 -135px;
            background-size: 36px auto;
        }

        &-item {
            flex: 0 0 100%;
            padding: 0 20px 0 49px;
        }

        &-input {
            width: 100%;
            border: 0;
            background: none;
            font-size: 16px;
            line-height: 137%;
            letter-spacing: 0.259325px;
            color: #6B7B8E;
            outline: none;
        }
    }


    .version-main & {
        max-width: 40%;
        padding-top: 30px;
        margin: 0;
        
        &__form {
            height: 66px;

            &:before {
                left: 32px;
                width: 28px;
                height: 28px;
                background-position: 0 -180px;
                background-size: auto;
            }

            &-item {
                padding: 0 20px 0 91px;
            }

            &-input {
                font-size: 21px;
                line-height: 113%;
                letter-spacing: 0.259325px;
            }
        }
    }
}

/* /Search ------------------------------------------------------------------- */
.version-lk,
.version-registration,
.version-login {
    .header{
        &__basket-coount{
            content: '';
            // display: inline-block;
            color: white;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background: #13A8FC;
            // margin-left: -24px;
            // vertical-align: -5px;
            // margin-right: 13px;
            
        }
    }
    
}




