.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    height: 30px;
    background: $gray;

    &__trigger {
        align-items: center;
        display: flex;
        cursor: pointer;
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 14px;
            background: url(../img/topbar/icons.png) no-repeat 0 0;
            vertical-align: -3px;
            margin-right: 2px;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 10px;
            height: 6px;
            background: url(../img/topbar/icons.png) no-repeat 0 -17px;
            vertical-align: 0;
            margin-left: 2px;
        }

        &_open:after {
            transform: rotate(180deg);
        }

        &-text {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #1C2734;

            &_mob {
                display: none;
            }
        }
    }
    &__login{
        display: none;
    }
    &__dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 30px;
        display: none;
        max-height: calc(100vh - 30px);
        background: $gray;
        overflow-y: auto;
    }

    &__box {
        display: flex;
        max-width: 1146px;
        padding-top: 30px;
        margin: 0 auto;
    }

    &__left {
        flex: 0 0 346px;
    }

    &__right {
        width: 100%;
        max-width: 750px;
        padding-left: 90px;
    }

    &__contacts {
        padding: 35px 21px 10px 32px;
        background: rgba(205, 214, 223, .4);
        border-radius: 6px;
        font-size: 15px;
        color: #6B7B8E;

        &-title {
            font-size: 20px;
            color: #405078;
            margin-bottom: 12px;
        }

        &-description {
            line-height: 160%;
            margin-bottom: 16px;
        }

        &-list {

            &-title {
                padding-top: 16px;
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 12px;
            }

            &-value {
                line-height: 120%;
                margin-bottom: 16px;
            }
        }

        &-box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }

        &-link {
            position: relative;
            padding-left: 23px;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%); 
                content: '';
                display: block;
                width: 17px;
                height: 17px;
                background: url(../img/bottom/icons.png) no-repeat;
            }

            &_phone:before {
                background-position: 0 0;
            }
        }
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        padding-top: 43px;

        &-col {
            flex: 0 0 33.33333%;
        }

        &-block {
            padding-bottom: 28px;
        }

        &-title {
            font-weight: 700;
            font-size: 12.5999px;
            line-height: 15px;
            text-transform: uppercase;
            color: #405078;
            margin-bottom: 16px;
        }

        &-item {
            font-size: 16.7999px;
            line-height: 20px;
            margin-bottom: 16px;
        }
    }
}
.version-lk{
    .topbar{
        &__login{
            // display: none;
            display: flex;
            align-items: center;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.01em;
            img{
                margin-right: 5px;
            }
            b{
                margin-left: 5px;
            }
            @media (min-width:768px) {
                right: 10px;
            }
            &--desk{
                display: none;
                @media (min-width:768px) {
                    display: flex;
                }
            }
            &--mobile{
                display: flex;
                @media (min-width:768px) {
                    display: none;
                }
            }
        }
    }
}