.books {
	padding: 64px 0 81px 0;
	
	&__panel {
		display: flex;
		align-items: baseline;
		margin-bottom: 44px;
	}

	&__head {
		margin-right: 145px;
	}
	
	.block-title {
		margin-bottom: 48px;
	}

	.tabs {
		display: flex;
		width: 100%;

		&__carousel {
			display: flex;
		}

		&__title {
			display: flex !important;
			justify-content: center;
			align-items: center;
			height: 40px;
			padding: 0 16px;
			font-weight: 600;
			font-size: 18px;
			line-height: 133%;
			letter-spacing: 0.2px;
			color: #6B7B8E;
			white-space: nowrap;
			cursor: pointer;
			margin-right: 8px;

			&:last-child {
				margin: 0;
			}
	
			&.current {
				background: $lightblue;
				border-radius: 8px 0px;
				color: #fff;
			}

			SPAN {
				display: block;
				padding-top: 4px;
				font-weight: 400;
				font-size: 14px;
				margin-left: 5px;
			}
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin-right: -16px;
		margin-left: -16px;
	}

	&__col {
		position: relative;
		flex: 0 0 16.66666%;
		width: 100%;
		max-width: 16.66666%;
		min-height: 1px;
		padding-right: 16px;
		padding-left: 16px;

		&[data-more-hidden] {
			padding-bottom: 0;

			.books__item {
				position: absolute;
				top: 9999px;
				left: -9999px;
				opacity: 0;
			}
		}

		&_whide {
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
		}
	}	

	&__item {
		position: relative;
		display: flex; 
        flex-direction: column;
        max-width: 272px;
		min-height: 100%;
		padding-bottom: 45px;
		margin: 0 auto;
	}

	&__label {
		position: absolute;
		top: -18px;
		right: 16px;
		display: flex;
		align-items: center;
		height: 36px;
		padding: 0 16px;
		border-radius: 6px 0;
		font-weight: 700;
		font-size: 15px;
		line-height: 133.98%;
		color: #fff;

		&_new {
			background: #13A8FC;
		}

		&_bestseller {
			background: #FF9F0F;
		}

		&_soon {
			background: #00E625;
		}

		&_set {
			background: #FFF50F;
			color: #000;
		}

		&_discount {
			background: #FF5056;
			font-weight: 600;
			font-size: 18px;
		}
	}

	&__photo {
        margin-bottom: 32px;

		IMG {
            display: block;
            box-shadow: 0px 10px 50px 3px rgba(130, 146, 186, 0.45);
			margin: 0 auto;
		}
	}
	
    &__price {
        font-family: $proximanovacn;
        font-weight: 600;
        font-size: 22px;
        line-height: 109%;
        margin-bottom: 24px;
    }

	&__name {
        font-family: $proximanovacn;
		font-weight: 400;
		font-size: 22px;
		line-height: 109%;
        margin-bottom: 8px;
        
        SPAN {
            font-weight: 600;
        }
	}

	&__text {
        font-size: 14px;
		line-height: 129%;
	}

	&__settitle {
		font-size: 44px;
		line-height: 47px;
	}

	&__settext {
		font-weight: 300;
		font-size: 20px;
		line-height: 36.36px;
		margin-bottom: 18px;
	}

	&__setprice {
		font-family: $proximanovacn;
        font-weight: 600;
        font-size: 23.5497px;
        line-height: 109%;
	}

	&__col_whide & {

		&__item {
			max-width: none;
			
			&-inner {
				padding: 50px;
				background: $gray;
				border: 1px solid #E4E9F2;
			}
		}
	}



	.version-books & {
		padding-top: 158px;

		&__panel {
			flex-direction: column;
			margin-bottom: 24px;
		}

		&__head {
			margin: 0 0 24px 0;

			.section-head__number {
				display: none;
			}
		}
	}
}
