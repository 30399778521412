.cooperation {
    position: relative;
    color: #fff;

    &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        content: '';
        background: $gray;
    }

    &__box {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &__left {
        flex: 0 0 58.65%;
        padding: 80px 84px;
        border-radius: 20px 0 0 0;
        background: linear-gradient(97.09deg, #5957B1 31.49%, #4595B7 69.39%);
    }

    &__right {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 41.35%;
        align-items: center;
        justify-content: center;
        padding: 5% 5% 0 5%;
        border-radius: 0 0 20px 0;
        background: #0C143B;

        &:before {
            position: absolute;
            left: 50%;
            top: -27px;
            transform: translate(-50%, 0);
            content: '';
            width: 330px;
            height: 169px;
            background: url(../img/cooperation/figure.png) no-repeat 0 0;
        }
    }

    &__logo {
        margin-bottom: 30px;
    }

    &__description {
        font-size: 16px;
        line-height: 156% ;
        letter-spacing: 0.2px;
        margin-bottom: 53px;
    }

    &__item {
        position: relative;
        padding: 0 0 16px 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 133%;
        letter-spacing: 0.2px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 14px;
            height: 24px;
            background: url(../img/cooperation/bullet.png) no-repeat 0 0;
        }

        &_2:before {
            transform: rotate(180deg);
        }
    }

    &__text {
        max-width: 565px;
        font-size: 24px;
        line-height: 133% ;
        letter-spacing: 0.266667px;
        text-align: center;
        margin-bottom: 25px;
    }

    &__title {
        font-weight: 600;
        font-size: 38px;
        line-height: 107.18%;
        letter-spacing: -0.01em;
        text-align: center;
        margin-bottom: 42px;
    }

    .button {
        max-width: 221px;
    }

}


