.events {
    padding: 120px 0 114px 0;
    background: $gray;

    .container {
        max-width: 1214px;
    }

	&__title {
        text-align: center;
        margin-bottom: 47px;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin-right: -16px;
		margin-left: -16px;
		margin-bottom: 20px;
	}

	&__col {
		position: relative;
		flex: 0 0 50%;
		width: 100%;
		max-width: 50%;
		min-height: 1px;
		padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 50px;
        color: #fff;

		&.main  {
			flex: 0 0 100%;
			max-width: 100%;
		}

		&[data-more-hidden] {
			padding-bottom: 0;

			.events__item {
				position: absolute;
				top: 9999px;
				left: -9999px;
				opacity: 0;
			}
		}
	}	

	&__item {
		position: relative;
		display: flex; 
		flex-direction: column;
		min-height: 100%;
        border-radius: 10px 0;
        overflow: hidden;
        background: #1f224c;
        margin: 0 auto;
        
        &:before,
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
        }

        &:before {
            z-index: 1;
            background: rgba(28, 39, 52, 0.3);
        }

        &:after  {
            z-index: 1;
            background: linear-gradient(0deg, rgba(31, 33, 74, 0.9) 26.88%, rgba(31, 33, 74, 0) 69.35%);
        }
	}

	&__info {
        position: absolute;
        z-index: 2;
        left: 32px;
        right: 150px;
        bottom: 32px;

        .main & {
            right: 180px;
		}
	}

	&__date {
		font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #9FAEBF;
		margin-bottom: 12px;
	}

	&__name {
		font-weight: 700;
        font-size: 22px;
        line-height: 127%;
        
        .main & {
            font-size: 44px;
            margin-bottom: 8px;
		}
	}

	&__text {
		font-size: 18px;
        line-height: 144%;
    }
    
    &__book {
        position: absolute;
        z-index: 2;
        right: 32px;
        bottom: 32px;
        box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.23);
    }
}



