.form {

    ::placeholder {
        /* Chrome/Opera/Safari/Firefox */
        color: #6B7B8E;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        color: #6B7B8E;
    }

    &__title {
        font-size: 26px;
        font-weight: 500;
        line-height: 92.3%;
        margin-bottom: 25px;

        &_small {
            font-size: 21px;
            line-height: 100%;
            margin-bottom: 15px;
        }
    }

    &__text {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.1;
        margin-bottom: 18px;
    }

    &__items {display: flex;}

    &__item {
        //flex: 1 0;
        min-height: 68px;

        &_textarea {
            min-height: 156px;
        }
    }

    &__input {
        width: 100%;
        height: 49px;
        padding: 0 20px;
        border: 0;
        border-radius: 6px;
        font-size: 16px;
        color: #0C143B;
        background-color: #EEEEF8;
        -webkit-appearance: none;
        outline: none;

        &_textarea {
            height: 137px;
            padding: 15px 20px;
        }

        &.error {
            border: 2px solid #ff2626;
            background: #fff;

            &::placeholder {
                /* Chrome/Opera/Safari/Firefox */
                color: #ff2626;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #ff2626;
            }
        }

        &.valid {
            border: 2px solid #219653;
            background: #fff;
            color: #219653;

            &::placeholder {
                /* Chrome/Opera/Safari/Firefox */
                color: #219653;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #219653;
            }
        }
    }

    &__button {
        height: 49px;
        border: 0;
        cursor: pointer;

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }

    &__footer {
        position: relative;
        display: block;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: #0C143B;
        cursor: pointer;
        margin-bottom: 20px;

        &-checkbox {
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 11px;
            height: 11px;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            background: #f6f6f6;

            input {display: none;}

            &-icon:before {
                display: none;
                content: url("../img/form/check.png");

                input:checked + & {display: block;}
            }
        }

        &-text {
            padding-left: 20px;

            & a {
                text-decoration: underline;
            }
        }

    }

    &_vertical & {

        &__items {
            flex-direction: column;
        }

        &__item {
            width: 100%;
            max-width: 428px;
        }

        &__more {
            max-width: 428px;
        }
    }

    &_horisontal & {

        &__title {margin-bottom: 23px;}

        &__item {
            margin-right: 25px;

            &:last-child {margin-right: 0;}
        }
    }

    &__group { margin-bottom: 20px;}
}

label.error {display: none !important;}

.widget-form-privacy {display: none !important;}

.send-success {color: #000000;}

.phone-info {
    padding-top: 10px;

    &__text {
        max-width: 290px;
        padding-bottom: 11px;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.04em;
        color: #222;
        text-transform: uppercase;
    }

    &__number {
        font-size: 21px;
        font-weight: 700;
        line-height: 100%;
        color: #000;

        &:hover,
        &:focus {
            color: #000;
            text-decoration: none;
        }
    }
}