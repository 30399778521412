p{
    margin: 0;
}
.social {

    padding: 20px 0;

    .container{
        @media (max-width:360px) {
            max-width: 1822px;
        }
    }

    &__connection-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 25px 0 25px 0;
    }

    &__connection-list {
        display: flex;
        margin-bottom: 30px;
    }

    &__connect-link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #F2F4F8;
        cursor: pointer;
        &:hover{
            background: #DADEE2;
        }
        &:not(:last-child){
            margin-right: 12px;
        }
        &--active{
            background: #DADEE2;
        }
    }

    &__connect-link-img {
        max-width: 20px;
        max-height: 20px;
        
    }

    &__connect-form {
        display: grid;
        grid-template-columns: 1fr 125px;
        max-width: 600px;
        input{
            max-width: 100%;
        }
        button{
            margin-top: 0;
            margin-left: 12px;
        }
        .social__input{
            flex-grow: 2;
            &:not(:last-child){
                flex-grow: 1;
            }
        }
    }

    input{
        background: #F2F4F8;
        border-radius: 6px;
        width: 100%;
        max-width: 428px;
        max-width: 100%;
        border: none;
        padding: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        transition: .5s opacity ease;
        &::placeholder{
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #6B7B8E;
        }
        &:hover{
            opacity: .6;
        }
        &:focus + .input-label{
            display: none;
        }
        &:not(:placeholder-shown) + .input-label{
            display: none;
        }
        
    }

    button{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        padding: 12px 30px;
        background: #FE334A;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        &:disabled {
            opacity: 0.7;
        }
    }


}

