@import '_variables.scss';

body {
    background: #fff;
    font-family: 'Proxima Nova', 'Arial', 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #0C143B;
}

.wrapper {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.container {max-width: 1822px;}

.rubl {
    font-family: $rouble;
}

img {
    max-width: 100%;
    border: 0;
}

a {
    color: inherit;
    outline: none;
    transition: 0.3s;

    &:active,
    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }

    &:hover {opacity: 0.8;}
}

ul {list-style: none;}

ul,
li,
dl,
dt,
dd {
    padding: 0;
    margin: 0;
}

[href^="tel:"] {white-space: nowrap;}

button, 
input, 
select, 
textarea {
    font-family: inherit;
    color: inherit;
}

::placeholder {color: inherit; opacity: 1;}
:-ms-input-placeholder {color: inherit; opacity: 1;}

.button {
    width: 100%;
    height: 48px;
    padding: 0;
    border: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    cursor: pointer;

    &_1,
    &_1:hover,
    &_1:focus {
        background: #FE334A;
        border-radius: 4px;
        color: #fff;
    }

    &_2,
    &_2:hover,
    &_2:focus {
        background: none;
        border: 1px solid #BCC7D3;
        border-radius: 6px;
        font-size: 16px;
        color: #6B7B8E;
    }

    &_link {
        display: flex;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &:hover {opacity: 0.8;}

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.more {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6B7B8E;

    &__button {
        max-width: 201px;
        margin-right: 28px;

        &:after {
            content:'';
            display: inline-block;
            width: 12px; 
            height: 8px;
            background: url(../img/common/icons.png) no-repeat 0 0;
            vertical-align: middle;
            margin-left: 15px;
        }
    }

    &__link {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.2px;
    }
}

.section-head {
    display: flex;
    align-items: flex-end;

    &__number {
        line-height: 200%;
        color: #707070;
        padding-left: 16px;
    }
}

H1 {
    font-weight: 700;
    font-size: 72px;
    line-height: 90%;
    margin: 0;
}

.section-title  {
    font-weight: 600;
    font-size: 42px;
    line-height: 114%;
    margin: 0;
}

.block-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 36.54px;
    margin: 0;
}

.block-subtitle {
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    margin: 0;
}

.back {
    position: relative;
    z-index: 11;
    display: flex;
    color: $lightblue;
    margin-bottom: 48px;

    &__link {
        display: flex; 
        justify-content: center;
        align-items: center;
        height: 48px; 
        padding: 0 15px;
        border: 2px solid  $lightblue;
        border-radius: 0 6px; 
        font-weight: 600;
        font-size: 16px;
        line-height: 109.62%;
        letter-spacing: 0.02em;

        &:before {
            content: ''; 
            display: block; 
            width: 17px;
            height: 16px; 
            background: url(../../img/common/icons.png) no-repeat 0 -120px; 
            margin-right: 12px;
        }
    }
}

.hidden {display: none;}

.showbox-hidden {display: none;}

.lazy {
    background-image: none !important;
    opacity: 0;

    &:before,
    &:after {content: none !important;}

    &-loaded {
        opacity: 1;
        transition: 0.1s;
    }
}


.tabsbox {
    display: none;

    &.visible {
        display: block;
    }
}



/* slick-carousel ------------------------------------------------------- */
.slick-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-arrow {
    position: relative;
    z-index: 1;
    display: block;
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 7px;
    cursor: pointer;

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        content: '';
        width: 20px;
        height: 14px;
        background: url(../img/common/icons.png) no-repeat 0 -11px;
    }
}

.slick-slider-prev {
    margin-right: 13px;
} 

.slick-slider-next {
    transform: rotate(-180deg);
    margin-left: 13px;
} 

.slick-slider-dots {
    display: flex;

    ul {
        display: flex;

        li {
            margin: 0 11px;
            
            button {
                display: block;
                width: 8px;
                height: 8px;
                padding: 0;
                background: transparent;
                border: 0.997714px solid #7B7B7B;
                border-radius: 50%;
                font-size: 0;
                line-height: 0;
                color: transparent;
                outline: none;
                cursor: pointer;
            }

            &.slick-active {

                button {
                    background: #7B7B7B;
                    border-color: #7B7B7B;
                }
            }
        }
    }
}


