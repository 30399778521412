.partners {
	padding: 40px 0 103px 0;

	&__title {
        margin-bottom: 37px;
	}

	&__list {
		display: flex;
		gap: 20px 100px;

        LI {
			font-size: 20px;
			
			A {
				text-decoration: underline;
			}
        }
    }
}
