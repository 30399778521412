/* Fonts */
@font-face {
	font-family: 'Proxima Nova';
	src: url("fonts/ProximaNova-Extrabld.woff2") format("woff2"), url("fonts/ProximaNova-Extrabld.woff") format("woff");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url("fonts/ProximaNova-Bold.woff2") format("woff2"), url("fonts/ProximaNova-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url("fonts/ProximaNova-Semibold.woff2") format("woff2"), url("fonts/ProximaNova-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url("fonts/ProximaNova-Regular.woff2") format("woff2"), url("fonts/ProximaNova-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url("fonts/ProximaNova-Light.woff2") format("woff2"), url("fonts/ProximaNova-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn';
	src: url("fonts/ProximaNovaCond-Bold.woff2") format("woff2"), url("fonts/ProximaNovaCond-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn';
	src: url("fonts/ProximaNovaCond-Semibold.woff2") format("woff2"), url("fonts/ProximaNovaCond-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Cn';
	src: url("fonts/ProximaNovaCond-Regular.woff2") format("woff2"), url("fonts/ProximaNovaCond-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'rouble';
	src: url("fonts/rouble.woff2") format("woff2"), url("fonts/rouble.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

/* Defaults */
body {
	background: #fff;
	font-family: 'Proxima Nova', 'Arial', 'Helvetica', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.25;
	color: #0C143B;
}

.wrapper {
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.container {
	max-width: 1822px;
}

.rubl {
	font-family: "rouble";
}

img {
	max-width: 100%;
	border: 0;
}

a {
	color: inherit;
	outline: none;
	transition: 0.3s;
}

a:active, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}

a:hover {
	opacity: 0.8;
}

ul {
	list-style: none;
}

ul,
li,
dl,
dt,
dd {
	padding: 0;
	margin: 0;
}

[href^="tel:"] {
	white-space: nowrap;
}

button,
input,
select,
textarea {
	font-family: inherit;
	color: inherit;
}

::placeholder {
	color: inherit;
	opacity: 1;
}

:-ms-input-placeholder {
	color: inherit;
	opacity: 1;
}

.button {
	width: 100%;
	height: 48px;
	padding: 0;
	border: 0;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.2px;
	cursor: pointer;
}

.button_1, .button_1:hover, .button_1:focus {
	background: #FE334A;
	border-radius: 4px;
	color: #fff;
}

.button_2, .button_2:hover, .button_2:focus {
	background: none;
	border: 1px solid #BCC7D3;
	border-radius: 6px;
	font-size: 16px;
	color: #6B7B8E;
}

.button_link {
	display: flex;
	padding: 0 10px;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.button:hover {
	opacity: 0.8;
}

.button:disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

.more {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #6B7B8E;
}

.more__button {
	max-width: 201px;
	margin-right: 28px;
}

.more__button:after {
	content: '';
	display: inline-block;
	width: 12px;
	height: 8px;
	background: url(../img/common/icons.png) no-repeat 0 0;
	vertical-align: middle;
	margin-left: 15px;
}

.more__link {
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.2px;
}

.section-head {
	display: flex;
	align-items: flex-end;
}

.section-head__number {
	line-height: 200%;
	color: #707070;
	padding-left: 16px;
}

H1 {
	font-weight: 700;
	font-size: 72px;
	line-height: 90%;
	margin: 0;
}

.section-title {
	font-weight: 600;
	font-size: 42px;
	line-height: 114%;
	margin: 0;
}

.block-title {
	font-weight: 600;
	font-size: 30px;
	line-height: 36.54px;
	margin: 0;
}

.block-subtitle {
	font-weight: 400;
	font-size: 30px;
	line-height: 37px;
	margin: 0;
}

.back {
	position: relative;
	z-index: 11;
	display: flex;
	color: #13A8FC;
	margin-bottom: 48px;
}

.back__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	padding: 0 15px;
	border: 2px solid #13A8FC;
	border-radius: 0 6px;
	font-weight: 600;
	font-size: 16px;
	line-height: 109.62%;
	letter-spacing: 0.02em;
}

.back__link:before {
	content: '';
	display: block;
	width: 17px;
	height: 16px;
	background: url(../../img/common/icons.png) no-repeat 0 -120px;
	margin-right: 12px;
}

.hidden {
	display: none;
}

.showbox-hidden {
	display: none;
}

.lazy {
	background-image: none !important;
	opacity: 0;
}

.lazy:before, .lazy:after {
	content: none !important;
}

.lazy-loaded {
	opacity: 1;
	transition: 0.1s;
}

.tabsbox {
	display: none;
}

.tabsbox.visible {
	display: block;
}

/* slick-carousel ------------------------------------------------------- */
.slick-navigation {
	display: flex;
	justify-content: center;
	align-items: center;
}

.slick-arrow {
	position: relative;
	z-index: 1;
	display: block;
	width: 40px;
	height: 40px;
	background: #fff;
	border: 1px solid #E0E0E0;
	border-radius: 7px;
	cursor: pointer;
}

.slick-arrow:before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	content: '';
	width: 20px;
	height: 14px;
	background: url(../img/common/icons.png) no-repeat 0 -11px;
}

.slick-slider-prev {
	margin-right: 13px;
}

.slick-slider-next {
	transform: rotate(-180deg);
	margin-left: 13px;
}

.slick-slider-dots {
	display: flex;
}

.slick-slider-dots ul {
	display: flex;
}

.slick-slider-dots ul li {
	margin: 0 11px;
}

.slick-slider-dots ul li button {
	display: block;
	width: 8px;
	height: 8px;
	padding: 0;
	background: transparent;
	border: 0.997714px solid #7B7B7B;
	border-radius: 50%;
	font-size: 0;
	line-height: 0;
	color: transparent;
	outline: none;
	cursor: pointer;
}

.slick-slider-dots ul li.slick-active button {
	background: #7B7B7B;
	border-color: #7B7B7B;
}

.form ::placeholder {
	/* Chrome/Opera/Safari/Firefox */
	color: #6B7B8E;
}

.form :-ms-input-placeholder {
	/* IE 10+ */
	color: #6B7B8E;
}

.form__title {
	font-size: 26px;
	font-weight: 500;
	line-height: 92.3%;
	margin-bottom: 25px;
}

.form__title_small {
	font-size: 21px;
	line-height: 100%;
	margin-bottom: 15px;
}

.form__text {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 18px;
}

.form__items {
	display: flex;
}

.form__item {
	min-height: 68px;
}

.form__item_textarea {
	min-height: 156px;
}

.form__input {
	width: 100%;
	height: 49px;
	padding: 0 20px;
	border: 0;
	border-radius: 6px;
	font-size: 16px;
	color: #0C143B;
	background-color: #EEEEF8;
	-webkit-appearance: none;
	outline: none;
}

.form__input_textarea {
	height: 137px;
	padding: 15px 20px;
}

.form__input.error {
	border: 2px solid #ff2626;
	background: #fff;
}

.form__input.error::placeholder {
	/* Chrome/Opera/Safari/Firefox */
	color: #ff2626;
}

.form__input.error:-ms-input-placeholder {
	/* IE 10+ */
	color: #ff2626;
}

.form__input.valid {
	border: 2px solid #219653;
	background: #fff;
	color: #219653;
}

.form__input.valid::placeholder {
	/* Chrome/Opera/Safari/Firefox */
	color: #219653;
}

.form__input.valid:-ms-input-placeholder {
	/* IE 10+ */
	color: #219653;
}

.form__button {
	height: 49px;
	border: 0;
	cursor: pointer;
}

.form__button:disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

.form__footer {
	position: relative;
	display: block;
	font-size: 11px;
	line-height: 14px;
	letter-spacing: -0.01em;
	color: #0C143B;
	cursor: pointer;
	margin-bottom: 20px;
}

.form__footer-checkbox {
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 11px;
	height: 11px;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	background: #f6f6f6;
}

.form__footer-checkbox input {
	display: none;
}

.form__footer-checkbox-icon:before {
	display: none;
	content: url("../img/form/check.png");
}

input:checked + .form__footer-checkbox-icon:before {
	display: block;
}

.form__footer-text {
	padding-left: 20px;
}

.form__footer-text a {
	text-decoration: underline;
}

.form_vertical .form__items {
	flex-direction: column;
}

.form_vertical .form__item {
	width: 100%;
	max-width: 428px;
}

.form_vertical .form__more {
	max-width: 428px;
}

.form_horisontal .form__title {
	margin-bottom: 23px;
}

.form_horisontal .form__item {
	margin-right: 25px;
}

.form_horisontal .form__item:last-child {
	margin-right: 0;
}

.form__group {
	margin-bottom: 20px;
}

label.error {
	display: none !important;
}

.widget-form-privacy {
	display: none !important;
}

.send-success {
	color: #000000;
}

.phone-info {
	padding-top: 10px;
}

.phone-info__text {
	max-width: 290px;
	padding-bottom: 11px;
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0.04em;
	color: #222;
	text-transform: uppercase;
}

.phone-info__number {
	font-size: 21px;
	font-weight: 700;
	line-height: 100%;
	color: #000;
}

.phone-info__number:hover, .phone-info__number:focus {
	color: #000;
	text-decoration: none;
}

/* Blocks */
.topbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 11;
	display: flex;
	align-items: center;
	height: 30px;
	background: #EEF1F7;
}

.topbar__trigger {
	align-items: center;
	display: flex;
	cursor: pointer;
	position: relative;
}

.topbar__trigger:before {
	content: '';
	display: inline-block;
	width: 13px;
	height: 14px;
	background: url(../img/topbar/icons.png) no-repeat 0 0;
	vertical-align: -3px;
	margin-right: 2px;
}

.topbar__trigger:after {
	content: '';
	display: inline-block;
	width: 10px;
	height: 6px;
	background: url(../img/topbar/icons.png) no-repeat 0 -17px;
	vertical-align: 0;
	margin-left: 2px;
}

.topbar__trigger_open:after {
	transform: rotate(180deg);
}

.topbar__trigger-text {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.01em;
	color: #1C2734;
}

.topbar__trigger-text_mob {
	display: none;
}

.topbar__login {
	display: none;
}

.topbar__dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 30px;
	display: none;
	max-height: calc(100vh - 30px);
	background: #EEF1F7;
	overflow-y: auto;
}

.topbar__box {
	display: flex;
	max-width: 1146px;
	padding-top: 30px;
	margin: 0 auto;
}

.topbar__left {
	flex: 0 0 346px;
}

.topbar__right {
	width: 100%;
	max-width: 750px;
	padding-left: 90px;
}

.topbar__contacts {
	padding: 35px 21px 10px 32px;
	background: rgba(205, 214, 223, 0.4);
	border-radius: 6px;
	font-size: 15px;
	color: #6B7B8E;
}

.topbar__contacts-title {
	font-size: 20px;
	color: #405078;
	margin-bottom: 12px;
}

.topbar__contacts-description {
	line-height: 160%;
	margin-bottom: 16px;
}

.topbar__contacts-list-title {
	padding-top: 16px;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 12px;
}

.topbar__contacts-list-value {
	line-height: 120%;
	margin-bottom: 16px;
}

.topbar__contacts-box {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.topbar__contacts-link {
	position: relative;
	padding-left: 23px;
}

.topbar__contacts-link:before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	content: '';
	display: block;
	width: 17px;
	height: 17px;
	background: url(../img/bottom/icons.png) no-repeat;
}

.topbar__contacts-link_phone:before {
	background-position: 0 0;
}

.topbar__menu {
	display: flex;
	justify-content: space-between;
	padding-top: 43px;
}

.topbar__menu-col {
	flex: 0 0 33.33333%;
}

.topbar__menu-block {
	padding-bottom: 28px;
}

.topbar__menu-title {
	font-weight: 700;
	font-size: 12.5999px;
	line-height: 15px;
	text-transform: uppercase;
	color: #405078;
	margin-bottom: 16px;
}

.topbar__menu-item {
	font-size: 16.7999px;
	line-height: 20px;
	margin-bottom: 16px;
}

.version-lk .topbar__login {
	display: flex;
	align-items: center;
	position: absolute;
	right: -10px;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.01em;
}

.version-lk .topbar__login img {
	margin-right: 5px;
}

.version-lk .topbar__login b {
	margin-left: 5px;
}

@media (min-width: 768px) {
	.version-lk .topbar__login {
		right: 10px;
	}
}

.version-lk .topbar__login--desk {
	display: none;
}

@media (min-width: 768px) {
	.version-lk .topbar__login--desk {
		display: flex;
	}
}

.version-lk .topbar__login--mobile {
	display: flex;
}

@media (min-width: 768px) {
	.version-lk .topbar__login--mobile {
		display: none;
	}
}

.header {
	position: fixed;
	width: 100%;
	max-width: 1920px;
	left: auto;
	right: auto;
	top: 30px;
	z-index: 10;
	font-weight: 500;
	color: #405078;
}

.page-scrolled .header {
	background: rgba(255, 255, 255, 0.95);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.header_nofix {
	position: static;
}

.header__box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 114px;
	transition: 0.3s;
}

.page-scrolled .header__box {
	min-height: 60px;
}

.header__left {
	display: flex;
	align-items: center;
}

.header__icon {
	display: none;
}

.header__icon-menu {
	position: relative;
	z-index: 11;
	display: block;
	width: 20px;
	height: 20px;
	background: url(../img/header/icons.png) no-repeat 0 -110px;
	cursor: pointer;
}

.header__icon-menu.open {
	background-position: 0 -145px;
}

.header__logo {
	position: relative;
	z-index: 11;
}

.header__menu {
	display: flex;
	justify-content: center;
}

.header__menu-item {
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin: 0 24px;
}

.version-books .header__menu-link_book {
	color: #13A8FC;
}

.header__right {
	position: relative;
	z-index: 11;
	display: flex;
	align-items: center;
}

.header__basket {
	font-weight: 600;
	font-size: 18px;
	line-height: 139%;
	white-space: nowrap;
	margin-right: 41px;
}

.header__basket:before {
	content: '';
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url(../img/header/icons.png) no-repeat 0 -70px;
	vertical-align: -5px;
	margin-right: 13px;
}

.header__basket-coount {
	display: none;
}

.header__profile {
	display: block;
	width: 48px;
	height: 48px;
	background: url(../img/header/icons.png) no-repeat 0 0;
	text-indent: -10000px;
}

/* Search ------------------------------------------------------------------- */
.search {
	max-width: 30%;
	padding-top: 15px;
	margin: 0 0 0 auto;
}

.version-registration .search,
.version-login .search {
	display: none;
}

.page-scrolled .search {
	padding: 0 0 10px 0;
}

.search__form {
	position: relative;
	display: flex;
	align-items: center;
	height: 50px;
	background: #EEEEF8;
	border-radius: 13.8393px;
	transition: 0.1s;
}

.page-scrolled .search__form {
	height: 50px;
}

.search__form:before {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translate(0, -50%);
	content: '';
	display: block;
	width: 24px;
	height: 24px;
	background: url(../img/header/icons.png) no-repeat 0 -135px;
	background-size: 36px auto;
}

.search__form-item {
	flex: 0 0 100%;
	padding: 0 20px 0 49px;
}

.search__form-input {
	width: 100%;
	border: 0;
	background: none;
	font-size: 16px;
	line-height: 137%;
	letter-spacing: 0.259325px;
	color: #6B7B8E;
	outline: none;
}

.version-main .search {
	max-width: 40%;
	padding-top: 30px;
	margin: 0;
}

.version-main .search__form {
	height: 66px;
}

.version-main .search__form:before {
	left: 32px;
	width: 28px;
	height: 28px;
	background-position: 0 -180px;
	background-size: auto;
}

.version-main .search__form-item {
	padding: 0 20px 0 91px;
}

.version-main .search__form-input {
	font-size: 21px;
	line-height: 113%;
	letter-spacing: 0.259325px;
}

/* /Search ------------------------------------------------------------------- */
.version-lk .header__basket-coount,
.version-registration .header__basket-coount,
.version-login .header__basket-coount {
	content: '';
	color: white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	background: #13A8FC;
}

.main {
	padding-top: 150px;
}

.main__carousel {
	padding-bottom: 49px;
	color: #fff;
}

.main__carousel .slick-track {
	display: flex;
	padding-top: 140px;
}

.main__carousel .slick-slide {
	height: auto;
	min-height: 470px;
}

.main__carousel .slick-slide > DIV {
	display: flex;
	min-height: 100%;
}

.main__carousel .slick-slide > DIV > DIV {
	display: flex;
	min-height: 100%;
}

.main__carousel .slick-slide:nth-child(2) .main__item {
	background: linear-gradient(97.09deg, #322F30 31.49%, #23236F 69.39%);
}

.main__carousel .slick-slide:nth-child(2) .main__item-figure {
	max-width: 50%;
}

@media (max-width: 1199px) {
	.main__carousel .slick-slide:nth-child(2) .main__item-figure {
		left: unset;
		right: 0;
		max-width: 80%;
	}
	.main__carousel .slick-slide:nth-child(2) .main__item-figure-img_desk {
		display: block !important;
	}
	.main__carousel .slick-slide:nth-child(2) .main__item-figure-img_mob {
		display: none !important;
	}
}

@media (max-width: 1199px) and (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(2) .main__item-figure {
		max-width: 70%;
		right: 0;
	}
	.main__carousel .slick-slide:nth-child(2) .main__item-figure-img_desk {
		display: none !important;
	}
	.main__carousel .slick-slide:nth-child(2) .main__item-figure-img_mob {
		display: block !important;
	}
}

@media (max-width: 1199px) and (max-width: 575px) {
	.main__carousel .slick-slide:nth-child(2) .main__item-figure {
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(2) .main__item-info {
		flex: 0 0 100%;
	}
}

.main__carousel .slick-slide:nth-child(3) .main__item {
	background: linear-gradient(90deg, #989999 0%, #404141 100%), linear-gradient(89.89deg, #5957B1 0.11%, #969696 34.18%);
}

.main__carousel .slick-slide:nth-child(3) .main__item-figure {
	left: 45%;
}

@media (max-width: 1199px) {
	.main__carousel .slick-slide:nth-child(3) .main__item-figure {
		left: unset;
		right: 0;
	}
	.main__carousel .slick-slide:nth-child(3) .main__item-figure-img_desk {
		display: block !important;
	}
	.main__carousel .slick-slide:nth-child(3) .main__item-figure-img_mob {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(3) .main__item-figure {
		max-width: 50%;
		right: 0;
		right: -100px;
	}
	.main__carousel .slick-slide:nth-child(3) .main__item-figure-img_desk {
		display: none !important;
	}
	.main__carousel .slick-slide:nth-child(3) .main__item-figure-img_mob {
		display: block !important;
	}
}

@media (max-width: 575px) {
	.main__carousel .slick-slide:nth-child(3) .main__item-figure {
		max-width: 100%;
		right: -120px;
	}
}

@media (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(3) .main__item-info {
		flex: 0 0 70%;
	}
}

.main__carousel .slick-slide:nth-child(4) .main__item {
	background: linear-gradient(97.09deg, #5A5FA3 31.49%, #4D8EAD 69.39%);
}

.main__carousel .slick-slide:nth-child(4) .main__item-figure {
	left: 45%;
}

@media (max-width: 1599px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-figure {
		left: 35%;
	}
}

@media (max-width: 1499px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-figure {
		left: 32%;
	}
}

@media (max-width: 1199px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-figure {
		left: unset;
		right: 0;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-figure {
		max-width: 80%;
		right: 0;
		right: -50px;
	}
	.main__carousel .slick-slide:nth-child(4) .main__item-figure-img_desk {
		display: none !important;
	}
	.main__carousel .slick-slide:nth-child(4) .main__item-figure-img_mob {
		display: block !important;
	}
}

@media (max-width: 575px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-figure {
		max-width: 100%;
		right: -50px;
	}
}

.main__carousel .slick-slide:nth-child(4) .main__item-info {
	flex: 0 0 50%;
	padding-bottom: 0;
}

@media (max-width: 1740px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-info {
		margin-bottom: 20px;
	}
}

@media (max-width: 1199px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-info {
		flex: 0 0 90%;
	}
}

@media (max-width: 767px) {
	.main__carousel .slick-slide:nth-child(4) .main__item-info {
		flex: 0 0 100%;
	}
}

.main__item {
	display: flex;
	width: 100%;
	min-height: 100%;
	background: linear-gradient(97.09deg, #5957B1 31.49%, #4595B7 69.39%);
	border-radius: 20px 0px;
}

.main__item-box {
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-height: 100%;
	padding: 0 87px 0 78px;
}

.main__item-box:hover {
	opacity: 1;
}

.main__item-info {
	position: relative;
	z-index: 1;
	flex: 0 0 45%;
	padding: 60px 0 20px 0;
}

.main__item-info .button {
	max-width: 197px;
	color: #fff;
}

.main__item-subtitle {
	font-weight: 800;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	color: #FFA800;
	margin-bottom: 35px;
}

.main__item-subtitle span {
	color: #31B3FC;
}

.main__item-author {
	font-family: "Proxima Nova Cn", "Arial", "Helvetica", sans-serif;
	font-weight: 600;
	font-size: 26px;
	line-height: 104%;
	letter-spacing: 0.2px;
	margin-bottom: 14px;
}

.main__item-title {
	font-weight: 700;
	font-size: 47px;
	line-height: 102%;
	text-transform: uppercase;
	margin-bottom: 22px;
}

.main__item-text {
	max-width: 495px;
	line-height: 150%;
	letter-spacing: 0.2px;
	margin-bottom: 27px;
}

.main__item-figure {
	position: absolute;
	bottom: 0;
	left: 32%;
}

.main__item-figure-img_mob {
	display: none !important;
}

.main__item-book {
	position: relative;
	text-align: center;
	margin-top: -93px;
}

.main__item-book-photo {
	margin-bottom: 22px;
}

.main__item-book-photo IMG {
	box-shadow: 0px 20px 50px 10px rgba(12, 10, 81, 0.3);
}

.main__item-book .button {
	max-width: 242px;
	margin: 0 auto;
}

.books {
	padding: 64px 0 81px 0;
}

.books__panel {
	display: flex;
	align-items: baseline;
	margin-bottom: 44px;
}

.books__head {
	margin-right: 145px;
}

.books .block-title {
	margin-bottom: 48px;
}

.books .tabs {
	display: flex;
	width: 100%;
}

.books .tabs__carousel {
	display: flex;
}

.books .tabs__title {
	display: flex !important;
	justify-content: center;
	align-items: center;
	height: 40px;
	padding: 0 16px;
	font-weight: 600;
	font-size: 18px;
	line-height: 133%;
	letter-spacing: 0.2px;
	color: #6B7B8E;
	white-space: nowrap;
	cursor: pointer;
	margin-right: 8px;
}

.books .tabs__title:last-child {
	margin: 0;
}

.books .tabs__title.current {
	background: #13A8FC;
	border-radius: 8px 0px;
	color: #fff;
}

.books .tabs__title SPAN {
	display: block;
	padding-top: 4px;
	font-weight: 400;
	font-size: 14px;
	margin-left: 5px;
}

.books__content {
	display: flex;
	flex-wrap: wrap;
	margin-right: -16px;
	margin-left: -16px;
}

.books__col {
	position: relative;
	flex: 0 0 16.66666%;
	width: 100%;
	max-width: 16.66666%;
	min-height: 1px;
	padding-right: 16px;
	padding-left: 16px;
}

.books__col[data-more-hidden] {
	padding-bottom: 0;
}

.books__col[data-more-hidden] .books__item {
	position: absolute;
	top: 9999px;
	left: -9999px;
	opacity: 0;
}

.books__col_whide {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.books__item {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 272px;
	min-height: 100%;
	padding-bottom: 45px;
	margin: 0 auto;
}

.books__label {
	position: absolute;
	top: -18px;
	right: 16px;
	display: flex;
	align-items: center;
	height: 36px;
	padding: 0 16px;
	border-radius: 6px 0;
	font-weight: 700;
	font-size: 15px;
	line-height: 133.98%;
	color: #fff;
}

.books__label_new {
	background: #13A8FC;
}

.books__label_bestseller {
	background: #FF9F0F;
}

.books__label_soon {
	background: #00E625;
}

.books__label_set {
	background: #FFF50F;
	color: #000;
}

.books__label_discount {
	background: #FF5056;
	font-weight: 600;
	font-size: 18px;
}

.books__photo {
	margin-bottom: 32px;
}

.books__photo IMG {
	display: block;
	box-shadow: 0px 10px 50px 3px rgba(130, 146, 186, 0.45);
	margin: 0 auto;
}

.books__price {
	font-family: "Proxima Nova Cn", "Arial", "Helvetica", sans-serif;
	font-weight: 600;
	font-size: 22px;
	line-height: 109%;
	margin-bottom: 24px;
}

.books__name {
	font-family: "Proxima Nova Cn", "Arial", "Helvetica", sans-serif;
	font-weight: 400;
	font-size: 22px;
	line-height: 109%;
	margin-bottom: 8px;
}

.books__name SPAN {
	font-weight: 600;
}

.books__text {
	font-size: 14px;
	line-height: 129%;
}

.books__settitle {
	font-size: 44px;
	line-height: 47px;
}

.books__settext {
	font-weight: 300;
	font-size: 20px;
	line-height: 36.36px;
	margin-bottom: 18px;
}

.books__setprice {
	font-family: "Proxima Nova Cn", "Arial", "Helvetica", sans-serif;
	font-weight: 600;
	font-size: 23.5497px;
	line-height: 109%;
}

.books__col_whide .books__item {
	max-width: none;
}

.books__col_whide .books__item-inner {
	padding: 50px;
	background: #EEF1F7;
	border: 1px solid #E4E9F2;
}

.version-books .books {
	padding-top: 158px;
}

.version-books .books__panel {
	flex-direction: column;
	margin-bottom: 24px;
}

.version-books .books__head {
	margin: 0 0 24px 0;
}

.version-books .books__head .section-head__number {
	display: none;
}

.collections {
	padding: 88px 0 112px 0;
	background: #EEF1F7;
}

.collections__carousel {
	padding-bottom: 49px;
}

.collections .slick-list {
	overflow: visible;
}

.collections__item {
	width: 475px;
	min-height: 231px;
	padding: 26px 0 16px 34px;
	border-radius: 12.6923px  0;
	background: url(../img/collections/bg.png) no-repeat 60% 26px;
	margin-right: 32px;
}

.collections__item_1 {
	background-color: #98C0D5;
}

.collections__item_2 {
	background-color: #A7B7AC;
}

.collections__item_3 {
	background-color: #BCAC9F;
}

.collections__item_4 {
	background-color: #A1C1BF;
}

.collections__item-box {
	display: flex;
	justify-content: space-between;
}

.collections__item-info {
	display: flex;
	flex-direction: column;
}

.collections__item-title {
	font-weight: 600;
	font-size: 25.3846px;
	line-height: 140%;
	letter-spacing: 0.253846px;
	margin-bottom: 10px;
}

.collections__item-text {
	flex: 1;
	font-size: 18px;
	line-height: 113%;
	margin-bottom: 10px;
}

.collections__item-number-value {
	display: inline-block;
	height: 35px;
	padding: 0 15px;
	background: rgba(231, 236, 239, 0.21);
	border-radius: 5.07692px;
	font-weight: 700;
	font-size: 16px;
	line-height: 35px;
	color: rgba(0, 0, 0, 0.41);
}

.collections__item-book {
	display: flex;
	align-items: flex-end;
}

.collections__item-photo_1 {
	margin-right: -35px;
}

.mentors {
	padding: 89px 0 103px;
}

.mentors__head {
	margin-bottom: 37px;
}

.mentors__content {
	display: flex;
	flex-wrap: wrap;
	margin-right: -30px;
	margin-left: -30px;
}

.mentors__col {
	position: relative;
	flex: 0 0 20%;
	width: 100%;
	max-width: 20%;
	min-height: 1px;
	padding-right: 30px;
	padding-left: 30px;
}

.mentors__col[data-more-hidden] {
	padding-bottom: 0;
}

.mentors__col[data-more-hidden] .mentors__item {
	position: absolute;
	top: 9999px;
	left: -9999px;
	opacity: 0;
}

.mentors__item {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 310px;
	min-height: 100%;
	padding-bottom: 45px;
	margin: 0 auto;
}

.mentors__photo {
	margin-bottom: 32px;
}

.mentors__photo IMG {
	display: block;
	border-radius: 10px 0;
	margin: 0 auto;
}

.mentors__info {
	flex-grow: 1;
}

.mentors__name {
	font-family: "Proxima Nova Cn", "Arial", "Helvetica", sans-serif;
	font-weight: 400;
	font-size: 26px;
	line-height: 123%;
	margin-bottom: 8px;
}

.mentors__name SPAN {
	font-weight: 700;
}

.mentors__post {
	line-height: 150%;
	letter-spacing: 0.186182px;
}

.mentors__more-btn {
	display: block;
	max-width: 484px;
	margin: 30px auto 0 auto;
}

.version-authors .mentors {
	padding-top: 158px;
}

.version-authors .mentors__head {
	margin: 0 0 56px 0;
}

.version-authors .mentors__head .section-head__number {
	display: none;
}

.cooperation {
	position: relative;
	color: #fff;
}

.cooperation:before {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	bottom: 0;
	content: '';
	background: #EEF1F7;
}

.cooperation__box {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.cooperation__left {
	flex: 0 0 58.65%;
	padding: 80px 84px;
	border-radius: 20px 0 0 0;
	background: linear-gradient(97.09deg, #5957B1 31.49%, #4595B7 69.39%);
}

.cooperation__right {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0 0 41.35%;
	align-items: center;
	justify-content: center;
	padding: 5% 5% 0 5%;
	border-radius: 0 0 20px 0;
	background: #0C143B;
}

.cooperation__right:before {
	position: absolute;
	left: 50%;
	top: -27px;
	transform: translate(-50%, 0);
	content: '';
	width: 330px;
	height: 169px;
	background: url(../img/cooperation/figure.png) no-repeat 0 0;
}

.cooperation__logo {
	margin-bottom: 30px;
}

.cooperation__description {
	font-size: 16px;
	line-height: 156%;
	letter-spacing: 0.2px;
	margin-bottom: 53px;
}

.cooperation__item {
	position: relative;
	padding: 0 0 16px 30px;
	font-weight: 600;
	font-size: 18px;
	line-height: 133%;
	letter-spacing: 0.2px;
}

.cooperation__item:before {
	position: absolute;
	top: 0;
	left: 0;
	content: '';
	width: 14px;
	height: 24px;
	background: url(../img/cooperation/bullet.png) no-repeat 0 0;
}

.cooperation__item_2:before {
	transform: rotate(180deg);
}

.cooperation__text {
	max-width: 565px;
	font-size: 24px;
	line-height: 133%;
	letter-spacing: 0.266667px;
	text-align: center;
	margin-bottom: 25px;
}

.cooperation__title {
	font-weight: 600;
	font-size: 38px;
	line-height: 107.18%;
	letter-spacing: -0.01em;
	text-align: center;
	margin-bottom: 42px;
}

.cooperation .button {
	max-width: 221px;
}

.events {
	padding: 120px 0 114px 0;
	background: #EEF1F7;
}

.events .container {
	max-width: 1214px;
}

.events__title {
	text-align: center;
	margin-bottom: 47px;
}

.events__content {
	display: flex;
	flex-wrap: wrap;
	margin-right: -16px;
	margin-left: -16px;
	margin-bottom: 20px;
}

.events__col {
	position: relative;
	flex: 0 0 50%;
	width: 100%;
	max-width: 50%;
	min-height: 1px;
	padding-right: 16px;
	padding-left: 16px;
	padding-bottom: 50px;
	color: #fff;
}

.events__col.main {
	flex: 0 0 100%;
	max-width: 100%;
}

.events__col[data-more-hidden] {
	padding-bottom: 0;
}

.events__col[data-more-hidden] .events__item {
	position: absolute;
	top: 9999px;
	left: -9999px;
	opacity: 0;
}

.events__item {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	border-radius: 10px 0;
	overflow: hidden;
	background: #1f224c;
	margin: 0 auto;
}

.events__item:before, .events__item:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
}

.events__item:before {
	z-index: 1;
	background: rgba(28, 39, 52, 0.3);
}

.events__item:after {
	z-index: 1;
	background: linear-gradient(0deg, rgba(31, 33, 74, 0.9) 26.88%, rgba(31, 33, 74, 0) 69.35%);
}

.events__info {
	position: absolute;
	z-index: 2;
	left: 32px;
	right: 150px;
	bottom: 32px;
}

.main .events__info {
	right: 180px;
}

.events__date {
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: #9FAEBF;
	margin-bottom: 12px;
}

.events__name {
	font-weight: 700;
	font-size: 22px;
	line-height: 127%;
}

.main .events__name {
	font-size: 44px;
	margin-bottom: 8px;
}

.events__text {
	font-size: 18px;
	line-height: 144%;
}

.events__book {
	position: absolute;
	z-index: 2;
	right: 32px;
	bottom: 32px;
	box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.23);
}

.news {
	padding: 118px 0 114px 0;
}

.news .container {
	max-width: 1214px;
}

.news__title {
	margin-bottom: 47px;
}

.news__content {
	display: flex;
	flex-wrap: wrap;
	margin-right: -16px;
	margin-left: -16px;
	margin-bottom: 20px;
}

.news__col {
	position: relative;
	flex: 0 0 50%;
	width: 100%;
	max-width: 50%;
	min-height: 1px;
	padding-right: 16px;
	padding-left: 16px;
	padding-bottom: 50px;
	color: #fff;
}

.news__col.main {
	flex: 0 0 100%;
	max-width: 100%;
}

.news__col[data-more-hidden] {
	padding-bottom: 0;
}

.news__col[data-more-hidden] .news__item {
	position: absolute;
	top: 9999px;
	left: -9999px;
	opacity: 0;
}

.news__item {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	border-radius: 10px 0;
	overflow: hidden;
	background: #1f224c;
	margin: 0 auto;
}

.news__item:before, .news__item:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
}

.news__item:before {
	z-index: 1;
	background: rgba(28, 39, 52, 0.3);
}

.news__item:after {
	z-index: 1;
	background: linear-gradient(0deg, rgba(31, 33, 74, 0.9) 26.88%, rgba(31, 33, 74, 0) 69.35%);
}

.news__info {
	position: absolute;
	z-index: 2;
	left: 32px;
	right: 32px;
	bottom: 32px;
}

.news__hashtag {
	font-weight: 700;
	font-size: 12px;
	line-height: 140%;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #9F8BEF;
	margin-bottom: 8px;
}

.news__date {
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.04em;
	color: #9FAEBF;
}

.news__name {
	font-weight: 700;
	font-size: 22px;
	line-height: 127%;
	margin-bottom: 12px;
}

.main .news__name {
	font-size: 44px;
	margin-bottom: 8px;
}

.news__text {
	font-size: 18px;
	line-height: 144%;
	margin-bottom: 12px;
}

.partners {
	padding: 40px 0 103px 0;
}

.partners__title {
	margin-bottom: 37px;
}

.partners__list {
	display: flex;
	gap: 20px 100px;
}

.partners__list LI {
	font-size: 20px;
}

.partners__list LI A {
	text-decoration: underline;
}

.bottom {
	padding: 30px 0 20px;
	background: #EEF1F7;
}

@media (min-width: 992px) {
	.bottom {
		padding: 50px 0 20px;
	}
}

.bottom .container {
	padding: 0 10px;
}

@media (min-width: 1200px) {
	.bottom .container {
		padding: 0 15px;
	}
}

.bottom__left {
	color: #405078;
}

@media (min-width: 1200px) {
	.bottom__wrapper {
		display: flex;
		justify-content: space-between;
	}
}

.bottom__img {
	max-width: 170px;
	margin-bottom: 20px;
}

@media (min-width: 992px) {
	.bottom__img {
		max-width: 290px;
		margin-bottom: 30px;
	}
}

.bottom__form-title {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 10px;
}

@media (min-width: 992px) {
	.bottom__form-title {
		font-size: 14px;
		line-height: 20px;
	}
}

.bottom .form {
	margin-bottom: 25px;
}

@media (min-width: 992px) {
	.bottom .form {
		margin-bottom: 40px;
	}
}

.bottom .form__items {
	flex-direction: row;
}

.bottom .form__item {
	flex-grow: 1;
	min-height: auto;
	max-width: 450px;
}

.bottom .form__item:last-child {
	max-width: 150px;
	flex-grow: 1;
}

@media (min-width: 992px) {
	.bottom .form__item:last-child {
		max-width: 170px;
	}
}

.bottom .form__item_email {
	background: #FFFFFF;
	border-radius: 4px 0px 0px 4px;
}

.bottom .form__item_email::placeholder {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #6B7B8E;
}

@media (min-width: 992px) {
	.bottom .form__item_email::placeholder {
		font-size: 16px;
		line-height: 19px;
	}
}

.bottom .form__input {
	background: white;
}

.bottom .form__button {
	font-weight: 800;
	font-size: 14px;
	line-height: 18px;
	color: #F2F4F8;
	background: #13A8FC;
	border-radius: 0px 4px 4px 0px;
}

@media (min-width: 992px) {
	.bottom .form__button {
		font-size: 16px;
		line-height: 20px;
	}
}

.bottom__nav {
	display: flex;
	flex-wrap: wrap;
}

.bottom__nav-link {
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 20px;
}

.bottom__nav-link:not(:last-child) {
	margin-right: 24px;
}

@media (min-width: 992px) {
	.bottom__nav-link {
		font-size: 16px;
		line-height: 16px;
	}
}

.bottom__right {
	color: #6B7B8E;
}

@media (min-width: 992px) {
	.bottom__right {
		display: grid;
		max-width: 665px;
		grid-template-columns: 2fr 3fr 1fr;
		column-gap: 50px;
	}
}

@media (min-width: 1200px) {
	.bottom__right {
		max-width: 300px;
		grid-template-columns: 1fr;
		column-gap: 0;
	}
}

.bottom__phone {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	display: block;
	margin-bottom: 10px;
}

@media (min-width: 992px) {
	.bottom__phone {
		font-size: 16px;
		line-height: 19px;
	}
}

.bottom__work-days {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 20px;
}

@media (min-width: 992px) {
	.bottom__work-days {
		font-size: 16px;
		line-height: 19px;
	}
}

.bottom__adres {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 7px;
	display: flex;
	flex-direction: column;
}

@media (min-width: 992px) {
	.bottom__adres {
		font-size: 16px;
		line-height: 19px;
	}
}

.bottom__mail {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #6B7B8E;
}

@media (min-width: 992px) {
	.bottom__mail {
		margin-top: 10px;
		font-size: 16px;
		line-height: 19px;
	}
}

.bottom__social-links {
	margin-top: 20px;
}

@media (min-width: 992px) {
	.bottom__social-links {
		margin-top: 0;
	}
}

.bottom__social-link:not(:last-child) {
	margin-right: 12px;
}

.footer {
	padding: 0 0 90px 0;
	background: #EEF1F7;
}

@media (min-width: 992px) {
	.footer {
		padding: 0 0 100px 0;
	}
}

@media (min-width: 1200px) {
	.footer {
		padding: 0 0 40px 0;
	}
}

.footer__box {
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #6B7B8E;
	flex-direction: column;
}

@media (min-width: 992px) {
	.footer__box {
		flex-direction: row;
	}
}

@media (min-width: 992px) {
	.footer__policy {
		padding-left: 50px;
	}
}

.bottom-menu {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	padding: 25px 0 24px 0;
	background: #fff;
	box-shadow: 0px -4px 12px rgba(64, 80, 120, 0.1);
}

.bottom-menu__list {
	display: flex;
	justify-content: space-around;
	max-width: 566px;
	color: #0044DB;
	margin: 0 auto;
}

.bottom-menu__link {
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: -0.01em;
	color: #0044DB;
	opacity: 0.5;
}

.bottom-menu__link:before {
	content: '';
	display: block;
	width: 21px;
	height: 20px;
	background: url(../img/bottom-menu/icons.png) no-repeat;
	margin: 0 auto;
	opacity: 0.5;
}

.bottom-menu__link_1:before {
	background-position: 0 0;
}

.bottom-menu__link_2:before {
	background-position: 0 -23px;
}

.bottom-menu__link_3:before {
	background-position: 0 -46px;
}

.bottom-menu__link_4:before {
	background-position: 0 -69px;
}

.bottom-menu__link_5:before {
	background-position: 0 -92px;
}

.bottom-menu__link_current {
	opacity: 1;
}

.bottom-menu__link_current:before {
	opacity: 1;
}

.filters {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-top: 24px;
	border-top: 1px solid #D7D8D9;
	margin-bottom: 64px;
}

.filters__head, .filters__footer {
	display: none;
}

.filter {
	position: relative;
	margin-bottom: 8px;
}

.filter__name {
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 45px 0 16px;
	border: 1px solid #DADEE2;
	border-radius: 6px;
	font-weight: 600;
	font-size: 14px;
	line-height: 143%;
	color: #6B7B8E;
	cursor: pointer;
	margin-right: 15px;
}

.filter__name:after {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translate(0, -50%);
	content: '';
	display: block;
	width: 14px;
	height: 8px;
	background: url(../img/common/icons.png) no-repeat 0 -40px;
}

.filter__name:hover:after {
	background-position: -25px -40px;
}

.filter__name.open:after {
	transform: rotate(180deg);
	margin-top: -4px;
}

.filter__name-open {
	display: none;
}

.filter__drop {
	position: absolute;
	z-index: 1;
	top: 100%;
	display: none;
	padding: 24px;
	background: #fff;
	box-shadow: 0px 4px 14px 3px rgba(130, 146, 186, 0.45);
	border: 1px solid #DADEE2;
	border-radius: 6px;
	margin-top: 5px;
}

.filter__drop .scrollblock {
	max-height: 300px;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #DADEE2 transparent;
	margin-right: -10px;
}

.filter__drop .scrollblock::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	border-radius: 2px;
}

.filter__drop .scrollblock::-webkit-scrollbar-track {
	border-radius: 2px;
	background: none;
}

.filter__drop .scrollblock::-webkit-scrollbar-thumb {
	width: 4px;
	height: 4px;
	background-color: #DADEE2;
	border-radius: 2px;
}

.filter__list-item {
	margin-bottom: 8px;
}

.filter__list-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;
	line-height: 20px;
	cursor: pointer;
}

.filter__list-label > DIV {
	white-space: nowrap;
}

.filter DT {
	font-weight: bold;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #6B7B8E;
	margin-bottom: 8px;
}

.filter DD {
	padding-bottom: 12px;
}

.choice-value {
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 45px 0 16px;
	border-radius: 6px;
	background: rgba(19, 168, 252, 0.1);
	font-size: 14px;
	line-height: 143%;
	color: #000;
	margin-right: 15px;
	margin-bottom: 8px;
}

.choice-value-del {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translate(0, -50%);
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background: url(../img/common/icons.png) no-repeat 0 -60px;
	cursor: pointer;
}

.choice-value-del:hover {
	background-position: -25px -60px;
}

.choice-delete {
	position: relative;
	display: flex;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 6px;
	background: rgba(19, 168, 252, 0.1);
	font-size: 14px;
	line-height: 143%;
	color: #000;
	text-indent: -10000px;
	cursor: pointer;
	margin-right: 15px;
	margin-bottom: 8px;
}

.choice-delete:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background: url(../img/common/icons.png) no-repeat 0 -60px;
}

.choice-delete:hover:after {
	background-position: -25px -60px;
}

.price-filter {
	width: 306px;
}

.price-filter__head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
}

.price-filter__head DIV {
	display: flex;
	align-items: center;
	font-size: 13px;
}

.price-filter__head SPAN {
	display: flex;
	align-items: center;
	width: 67px;
	height: 25px;
	padding: 0 8px;
	background: #F8F8F8;
	border-radius: 4px;
	font-size: 14px;
	color: #6B7B8E;
	margin-left: 12px;
}

.price-filter__slider {
	position: relative;
	height: 2px;
	background: #DADEE2;
}

.price-filter__slider DIV {
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	background: #13A8FC;
}

.price-filter__slider SPAN {
	position: absolute;
	z-index: 2;
	top: -12px;
	width: 24px;
	height: 24px;
	background: #fff;
	border: 2px solid #13A8FC;
	border-radius: 50%;
	cursor: default;
	outline: none;
	margin-left: -12px;
}

.checkbox-box {
	position: relative;
	width: 19px;
	margin-left: 10px;
}

.checkbox-box .checkbox {
	position: absolute;
	z-index: 2;
	left: 0;
	top: -5px;
	padding: 0;
	opacity: 0;
	border: none;
	margin: 0 !important;
}

.checkbox-box .checkbox,
.checkbox-box .checkbox + .checkbox-icon {
	width: 9px;
	height: 9px;
	padding: 0;
	margin: 0;
}

.checkbox-box .checkbox + .checkbox-icon {
	position: absolute;
	z-index: 1;
	left: 0;
	top: -5px;
	background-position: 0 0;
	border: none;
	text-align: center;
}

.checkbox-box .checkbox:checked + .checkbox-icon {
	background: url(../img/common/icons.png) no-repeat -25px 0;
}

p {
	margin: 0;
}

.social {
	padding: 20px 0;
}

@media (max-width: 360px) {
	.social .container {
		max-width: 1822px;
	}
}

.social__connection-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #000000;
	margin: 25px 0 25px 0;
}

.social__connection-list {
	display: flex;
	margin-bottom: 30px;
}

.social__connect-link {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: #F2F4F8;
	cursor: pointer;
}

.social__connect-link:hover {
	background: #DADEE2;
}

.social__connect-link:not(:last-child) {
	margin-right: 12px;
}

.social__connect-link--active {
	background: #DADEE2;
}

.social__connect-link-img {
	max-width: 20px;
	max-height: 20px;
}

.social__connect-form {
	display: grid;
	grid-template-columns: 1fr 125px;
	max-width: 600px;
}

.social__connect-form input {
	max-width: 100%;
}

.social__connect-form button {
	margin-top: 0;
	margin-left: 12px;
}

.social__connect-form .social__input {
	flex-grow: 2;
}

.social__connect-form .social__input:not(:last-child) {
	flex-grow: 1;
}

.social input {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 428px;
	max-width: 100%;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
}

.social input::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.social input:hover {
	opacity: .6;
}

.social input:focus + .input-label {
	display: none;
}

.social input:not(:placeholder-shown) + .input-label {
	display: none;
}

.social button {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	padding: 12px 30px;
	background: #FE334A;
	border-radius: 4px;
	border: none;
	cursor: pointer;
}

.social button:disabled {
	opacity: 0.7;
}
