.bottom {
    padding: 30px 0 20px;
    background: $gray ;
    @media (min-width:992px) {
        padding: 50px 0 20px;
    }
    
    .container{
        padding: 0 10px;
        @media (min-width:1200px) {
            padding: 0 15px;
        }
    }
    &__left {
        color: #405078;
    }

    &__wrapper {
        
        @media (min-width:1200px) {
            display: flex;
            justify-content: space-between;
            // grid-template-columns: 8fr 4fr ;
            // row-gap: 20px;
            // column-gap: ;
        }
    }

    &__img {
        max-width: 170px;
        margin-bottom: 20px;
        @media (min-width:992px) {
            max-width: 290px;
            margin-bottom: 30px;
        }
    }

    &__form-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        @media (min-width:992px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .form {
        margin-bottom: 25px;
        @media (min-width:992px) {
            margin-bottom: 40px;
        }
        &__wrapper {
        }
    
        &__items {
            flex-direction: row;
        }
    
        &__item {
            flex-grow: 1;
            min-height: auto;
            max-width: 450px;
            &:last-child{
                max-width: 150px;
                flex-grow: 1;
                @media (min-width:992px) {
                    max-width: 170px;
                }
            }
    
            &_email {
                
                background: #FFFFFF;
                border-radius: 4px 0px 0px 4px;
                &::placeholder{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #6B7B8E;
                    @media (min-width:992px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
    
            &_button {
                
            }
        }
    
        &__input {
            background: white;
        }
    
        &__button {
            font-weight: 800;
            font-size: 14px;
            line-height: 18px;
            color: #F2F4F8;
            background: #13A8FC;
            border-radius: 0px 4px 4px 0px;
            @media (min-width:992px) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    &__nav {
        display: flex;
        flex-wrap: wrap;
    }

    &__nav-link {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 20px;
        &:not(:last-child){
            margin-right: 24px;
        }
        @media (min-width:992px) {
            font-size: 16px;
            line-height: 16px;
        }
    }
    &__right{
        color: #6B7B8E;
        @media (min-width:992px) {
            display: grid;
            max-width: 665px;
            grid-template-columns: 2fr 3fr 1fr;
            column-gap: 50px;
        }
        @media (min-width:1200px) {
            max-width: 300px;
            grid-template-columns: 1fr;
            // row-gap: 20px;
            column-gap: 0;
        }
        
    }
    &__phones {
    }

    &__phone {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: block;
        margin-bottom: 10px;
        @media (min-width:992px) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__work-days {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
        @media (min-width:992px) {
            font-size: 16px;
            line-height: 19px;
        }
        
        
    }

    &__adres {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 7px;
        display: flex;
        flex-direction: column;
        @media (min-width:992px) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__mail {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #6B7B8E;
        @media (min-width:992px) {
            margin-top: 10px;
            font-size: 16px;
            line-height: 19px;
        }
      
    }

    &__social-links {
        margin-top: 20px;
        @media (min-width:992px) {
            margin-top: 0;
        }
    }

    &__social-link {
        &:not(:last-child){
            margin-right: 12px;
        }
    }
   
}


.bottom {

    &_left {
    }

    &_right {
    }

    &__wrapper {
    }

    &__img {
    }

    &__form-title {
    }

    &__nav {
    }

    &__nav-link {
    }

    &__phones {
    }

    &__phone {
    }

    &__work-days {
    }

    &__adres {
    }

    &__mail {
    }

    &__social-links {
    }

    &__social-link {
    }
}
.lazy {
}
.container {
}

.button {
}
