.main {
    padding-top: 150px;

    &__carousel {
        padding-bottom: 49px;
        color: #fff;

        .slick-track{
            display: flex;
            padding-top: 140px;
        }

        .slick-slide {
            height: auto;
            min-height: 470px;
            
            & > DIV {
                display: flex;
                min-height: 100%;

                & > DIV {
                    display: flex;
                    min-height: 100%;
                }
            }
            &:nth-child(2){
                .main__item{
                    background: linear-gradient(97.09deg, #322F30 31.49%, #23236F 69.39%);
                }
                .main__item-figure{
                    max-width: 50%;
                    @media(max-width:1199px){
                        left: unset;
                        right: 0;
                        max-width: 80%;
                        &-img_desk{
                            display: block !important;
                        }
                        &-img_mob{
                            display: none !important;
                        }
                        @media(max-width:767px){
                            max-width: 70%;
                            right: 0;
                            &-img_desk{
                                display: none !important;
                            }
                            &-img_mob{
                                display: block !important;
                            }
                        }
                        @media(max-width:575px){
                            max-width: 100%;
                        }
                        
                      
                    }
                }
                .main__item-info{
                    @media(max-width:767px){
                        flex: 0 0 100%;
                    }
                }
                
            }
            &:nth-child(3){
                .main__item{
                    background: linear-gradient(90deg, #989999 0%, #404141 100%), linear-gradient(89.89deg, #5957B1 0.11%, #969696 34.18%);
                }
                .main__item-figure{
                    left: 45%;
                    
                    @media(max-width:1199px){
                        left: unset;
                        right: 0;
                        &-img_desk{
                            display: block !important;
                        }
                        &-img_mob{
                            display: none !important;
                        }
                        
                        
                      
                    }
                    @media(max-width:767px){
                        max-width: 50%;
                        right: 0;
                        right: -100px;
                        &-img_desk{
                            display: none !important;
                        }
                        &-img_mob{
                            display: block !important;
                        }
                    }
                    @media(max-width:575px){
                        max-width: 100%;
                        right: -120px;
                    }
                }
                .main__item-info{
                    @media(max-width:767px){
                        flex: 0 0 70%;
                        
                    }
                }
                
            }
            &:nth-child(4){
                .main__item{
                    background: linear-gradient(97.09deg, #5A5FA3 31.49%, #4D8EAD 69.39%);
                }
                .main__item-figure{
                    left: 45%;
                    @media(max-width:1599px){
                        left: 35%;
                    }
                    @media(max-width:1499px){
                        left: 32%;
                    }
                    @media(max-width:1199px){
                        left: unset;
                        right: 0;
                        max-width: 100%;
                        
                    }
                    @media(max-width:767px){
                        max-width: 80%;
                        right: 0;
                        right: -50px;
                        &-img_desk{
                            display: none !important;
                        }
                        &-img_mob{
                            display: block !important;
                        }
                    }
                    @media(max-width:575px){
                        max-width: 100%;
                        right: -50px;
                    }
                }
                .main__item-info{
                    flex: 0 0 50%;
                    padding-bottom: 0;
                    @media(max-width:1740px){
                        margin-bottom: 20px;
                        
                    }
                    @media(max-width:1199px){
                        flex: 0 0 90%;
                        
                    }
                    @media(max-width:767px){
                        flex: 0 0 100%;
                        
                    }
                }
                
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;
        min-height: 100%;
        background: linear-gradient(97.09deg, #5957B1 31.49%, #4595B7 69.39%);
        border-radius: 20px 0px;

        &-box {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-height: 100%;
            padding: 0 87px 0 78px;
            

            &:hover {
                opacity: 1;
            }
        }

        &-info {
            position: relative;
            z-index: 1;
            flex: 0 0 45%;
            padding: 60px 0 20px 0;

            .button {
                max-width: 197px;
                color: #fff;
            }
        }

        &-subtitle {
            font-weight: 800;
            font-size: 12px;
            line-height: 100% ;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #FFA800;
            margin-bottom: 35px;
            span{
                color: #31B3FC;
            }
        }

        &-author {
            font-family: $proximanovacn;
            font-weight: 600;
            font-size: 26px;
            line-height: 104%;
            letter-spacing: 0.2px;
            margin-bottom: 14px;
        }

        &-title {
            font-weight: 700;
            font-size: 47px;
            line-height: 102%;
            text-transform: uppercase;
            margin-bottom: 22px;
        }

        &-text {
            max-width: 495px;
            line-height: 150%;
            letter-spacing: 0.2px;
            margin-bottom: 27px;
        }

        &-figure {
            position: absolute;
            bottom: 0;
            left: 32%;

            &-img {
                
                &_mob {
                    display: none !important;
                }
            }
        }

        &-book {
            position: relative;
            text-align: center;
            margin-top: -93px;

            &-photo {
                margin-bottom: 22px;

                IMG {
                    box-shadow: 0px 20px 50px 10px rgba(12, 10, 81, 0.3);
                }
            }

            .button {
                max-width: 242px;
                margin: 0 auto;
            }
        }
    }

   
}


