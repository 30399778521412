.collections {
    padding: 88px 0 112px 0;
    background: $gray;

    &__carousel {
        padding-bottom: 49px;
    }

    .slick-list {
        overflow: visible;
    }

    &__item {
        width: 475px;
        min-height: 231px;
        padding: 26px 0 16px 34px;
        border-radius: 12.6923px  0;
        background: url(../img/collections/bg.png) no-repeat 60% 26px;
        margin-right: 32px;

        &_1 {
            background-color: #98C0D5;
        }

        &_2 {
            background-color: #A7B7AC;
        }

        &_3 {
            background-color: #BCAC9F;
        }

        &_4 {
            background-color: #A1C1BF;
        }

        &-box {
            display: flex;
            justify-content: space-between;
        }

        &-info {
            display: flex;
            flex-direction: column;
        }

        &-title {
            font-weight: 600;
            font-size: 25.3846px;
            line-height: 140%;
            letter-spacing: 0.253846px;
            margin-bottom: 10px;
        }

        &-text {
            flex: 1;
            font-size: 18px;
            line-height: 113%;
            margin-bottom: 10px;
        }

        &-number {

            &-value {
                display: inline-block;
                height: 35px;
                padding: 0 15px;
                background: rgba(231, 236, 239, 0.21);
                border-radius: 5.07692px;
                font-weight: 700;
                font-size: 16px;
                line-height: 35px;
                color: rgba(0, 0, 0, 0.41);
            }
        }

        &-book {
            display: flex;
            align-items: flex-end;
        }

        &-photo{
            
            &_1 {
                margin-right: -35px;
            }
        }
    }

}


