.footer {
    padding: 0 0 90px 0;
    background: $gray;
    @media (min-width:992px) {
        padding: 0 0 100px 0;
    }
    @media (min-width:1200px) {
        padding: 0 0 40px 0;
    }

    &__box {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6B7B8E;
        flex-direction: column;
        @media (min-width:992px) {
            flex-direction: row;
        }
    }

    &__copy {
        // flex: 0 0 346px;
    }

    &__policy {
        @media (min-width:992px) {
            padding-left: 50px;
        }
        
    }
}
